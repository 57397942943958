import { useState, useEffect, useCallback } from 'react';
import { guest } from './data/guest';

function useGuestData() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const getListGuest = () => {
    return guest.map((item, index) => {
      return {
        code: `AR-${index}`,
        name: item,
        desc: '',
        shift: 1,
        isAttended: '',
        updatedBy: '',
        isExchanged: '',
        exchangedAt: '',
        exchangedBy: '',
      };
    });
  };

  const handleFetch = useCallback(async () => {
    setLoading(true);

    try {
      setData(getListGuest());
      setLoading(false);
    } catch {
      console.error('ERR_WHEN_HIT_DATA');
      setData([]);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    handleFetch();
  }, [handleFetch]);

  return { data, loading, refetch: handleFetch };
}

export default useGuestData;
