export const guest = [
  'Lumina',
  'Mira',
  'Amoy',
  'Dania Ardiyatna',
  'Nanda Dwi W',
  'St. Fakhriyah',
  'Mayangda Sari A',
  'Rianti Utami R',
  'Andita ',
  'Tiara Kusuma H',
  'Esica Liyana',
  'Nalita Septyarani',
  'Okta',
  'Riska',
  'Tiara Nuraidha',
  'Ine Ananda',
  'Fitri Wulandari',
  'Mas Dwi',
  'Mas Hanizar',
  'Mas Topan',
  'Julian',
  'Michael',
  'Naufal Zuhdi',
  'Mba Evita',
  'Tama',
  'Betha',
  'Mas Petrus',
  'Mba Nelly',
  'Mba Nana',
  'Mba Tanti',
  'Mba Athie',
  'Mba Rina',
  'Mba Kiki',
  'Mba Tika ',
  'Mba Titi',
  'Mba Dinda',
  'Mba Rere',
  'Mba Didi',
  'Ka Firdha ',
  'Mas Panji',
  'Mas Indra',
];
